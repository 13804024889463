<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl">Validations</h1>
    <ValidationsTable
      :validations="$store.state.validations"
    />
  </div>
</template>

<script>
import ValidationsTable from '@/components/validations/ValidationsTable.vue';

export default {
  components: {
    ValidationsTable
  },
  created () {
    this.getValidations();
  },
  methods: {
    async getValidations () {
      this.$store
        .dispatch('getValidationsQuery')
        .then(res => {
          if (res.errors) {
            // go set sessions errors globally
            this.$store.dispatch('setValidationsErrors', res.errors);
          }
        })
        .catch(err => {
          console.log(err.message);
        });
    }
  }
};
</script>
